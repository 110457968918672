[class^="multi-color-icon-"]:before,
[class*=" multi-color-icon-"]:before,
[class^="multi-color-icon-"]:after,
[class*=" multi-color-icon-"]:after {
    font-family: "atriummenu";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    font-size: 120% !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="multi-color-icon-"]:after,
[class*=" multi-color-icon-"]:after {
    margin-left: -1em;
}