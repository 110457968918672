@import "style/helpers.css";
@import "antd.css";
@import "style/customIcons.css";

* {
  font-family: "Bebas Neue Pro", sans-serif;
}

.ant-layout-header {
  background: rgba(0,0,0,.07)
}

.ant-layout-content,
.ant-layout-sider,
.ant-layout-footer,
.grey-gb {
    background: rgba(0, 0, 0, .01)
}

.rank-quick-link-button {
    transition: .5s;
}

.rank-quick-link-button:hover {
    background: rgba(0, 0, 0, .05);
}

/*button*/
.ant-btn.btn-width {
    padding-left: 30px;
    padding-right: 30px;
    min-width: 120px;
}

/*Tab wrap*/
.tabs-wrap .ant-tabs-top > .ant-tabs-nav::before,
.tabs-wrap .ant-tabs-bottom > .ant-tabs-nav::before,
.tabs-wrap .ant-tabs-top > div > .ant-tabs-nav::before,
.tabs-wrap .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid #bbb
}

.tabs-wrap .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #df120f;
}

.tabs-wrap .ant-tabs-ink-bar {
    background: #df120f;
    height: 4px;
}

.tabs-wrap .ant-tabs-tab.ant-tabs-tab-active {
    background: rgba(0, 0, 0, .07);
}

.tabs-wrap .ranking-seasons-filter .ant-select {
    width: 120px;
}

.tabs-wrap .ant-tabs-tab {
    padding: 10px 25px;
    text-transform: uppercase;
    font-weight: bold;
}

/*shadow*/
.shadow-1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.shadow-1:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.quick-link-card {
    min-height: 95px;
    cursor: pointer;
}